import React from "react";
import { Section } from "../components";
import { Row, Col } from "react-bootstrap";
import contact from "../data/contact.json";
import { Formik, Field, ErrorMessage } from "formik";
import { useForm, ValidationError } from '@formspree/react';
import * as Yup from "yup";


interface MyProps {
  formState: {
    displayBox: boolean;
  };
  setFormState: any;
}

interface FormValues {
  Name: string;
  Email: string;
  Subject: string;
  Message: string;
}



const CustomErrors = (props: any) => {
  const { children } = props;
  return <div className="input-errors">{children}</div>;
};

const CustomInput = ({ field, form, ...props }: any) => {
  return (
    <div>
      <div>
        <ErrorMessage name={field.name} component={CustomErrors} />
      </div>
      {props.type === "textarea" && (
        <textarea {...field} {...props} id={field.name} type={props.type} />
      )}
      {props.type !== "textarea" && (
        <div>
          <input {...field} {...props} id={field.name} type={props.type} />
        </div>
      )}
    </div>
  );
};

export const Contact: React.FC<MyProps> = ({ formState, setFormState }) => {
  const formId = process.env.FORMSPREE_ID
  const [state, handleSubmit] = useForm(formId); 

  const initialValues: FormValues = {
    Name: "",
    Email: "",
    Subject: "",
    Message: "",
  };

  const userSchema = Yup.object().shape({
    Name: Yup.string().required("Comment vous appelez vous ?"),
    Email: Yup.string()
      .email("Format d'Email incorrect.")
      .required("un email = une réponse."),
    Subject: Yup.string().required("Avec un petit objet c'est plus sympa."),
    Message: Yup.string().required("Laissez moi un petit mot."),
  });

  React.useEffect(() => {
    if (state.succeeded) {
      setFormState({ ...formState, displayBox: true });
    }
  }, [state.succeeded, formState, setFormState]);

  return (
    <Section id="contact" title="Gardons contact">
      <Row>
        <Col md={4}>
          <div className="contact-info">
            <h3 dangerouslySetInnerHTML={{ __html: contact.title }} />
            <div dangerouslySetInnerHTML={{ __html: contact.subTitle }} />
          </div>
        </Col>
        <Col md={8}>
          <Formik
            initialValues={initialValues}
            validationSchema={userSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Field
                      className="kd-form-control"
                      type="text"
                      name="Name"
                      component={CustomInput}
                      placeholder="Vos nom et prénom"
                    />
                  </Col>
                  <Col md={6}>
                    <Field
                      className="kd-form-control"
                      type="email"
                      name="Email"
                      component={CustomInput}
                      placeholder="Votre email"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field
                      className="kd-form-control"
                      type="text"
                      name="Subject"
                      component={CustomInput}
                      placeholder="Objet du message"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Field
                      className="kd-form-control textarea"
                      type="textarea"
                      name="Message"
                      component={CustomInput}
                      placeholder="Message"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div>
                      <input
                        className="btn btn-kd"
                        type="submit"
                        value="Envoyer le message"
                        disabled={isSubmitting || state.submitting}
                      />
                    </div>
                    {state?.errors?.length > 0 && (
                      <div className="input-errors">
                        <ValidationError field="email" prefix="Email" errors={state.errors} />
                      </div>
                    )}
                    {state.succeeded && <p className="mt-6">Message envoyé avec succès !</p>}
                  </Col>
                </Row>
              </form>
            )}
          </Formik>
        </Col>
      </Row>
    </Section>
  );
};

export default Contact;
